import React from "react";
import Layout from "../components/Layout";
import { Text, Container, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

export default function ContactUS() {
  return (
    <Layout>
      <Helmet>
        <title>Thank you</title>
      </Helmet>
      <Container maxW={"7xl"} py={5}>
        <Image
          src="https://bsc-icc.com/landing/wp-content/uploads/2021/03/GettyImages-185002046-5772f4153df78cb62ce1ad69-768x432.jpg"
          alt="thank you"
          // style = sx
          sx={{
            margin: "auto",
          }}
        />
        <Text fontSize={"1.5rem"} textAlign={"center"} marginTop={"2rem"}>
          You will get your ISO Certification Guide with in 24 Hours on your
          email.
        </Text>
      </Container>
    </Layout>
  );
}
